import React from "react";

import Advantages from "./Agvantages";

import note1 from '../img/note1.svg'
import note2 from '../img/note2.svg'
import arrow from '../img/pointArrow.svg'
import plane1 from '../img/preschoolPlane1.svg'
import plane2 from '../img/preschoolPlane2.svg'


function PreparingForSchool() {
    return(
        <div className="main__section__preschool" id="course">
            <img src={plane1} alt="img" className="main__section__preschool__plane1"/>
            <img src={plane2} alt="img" className="main__section__preschool__plane2"/>
            <div className="main__section__preschool__content" id="school">
                <div className="main__section__preschool__content__block1">
                    <div className="main__section__preschool__content__block1__info">
                        <h1 className="main__section__preschool__content__block1__info__title">
                            Как мы это делаем?
                        </h1>
                        <div className="main__section__preschool__content__block1__info__point">
                            <img src={arrow} alt="img" className="main__section__preschool__content__block1__info__point__img"/>
                            <p className="main__section__preschool__content__block1__info__point__text">
                                Развиваем самостоятельность
                            </p>
                        </div>
                        <div className="main__section__preschool__content__block1__info__point">
                            <img src={arrow} alt="img" className="main__section__preschool__content__block1__info__point__img"/>
                            <p className="main__section__preschool__content__block1__info__point__text">
                                Тесно взаимодействуем с родителями
                            </p>
                        </div>
                        <div className="main__section__preschool__content__block1__info__point">
                            <img src={arrow} alt="img" className="main__section__preschool__content__block1__info__point__img"/>
                            <p className="main__section__preschool__content__block1__info__point__text">
                                Мы очень любим детей и умеем с ними работать
                            </p>
                        </div>
                        <div className="main__section__preschool__content__block1__info__point">
                            <img src={arrow} alt="img" className="main__section__preschool__content__block1__info__point__img"/>
                            <p className="main__section__preschool__content__block1__info__point__text">
                                Формируем положительную мотивацию к учебной деятельности
                            </p>
                        </div>
                        <div className="main__section__preschool__content__block1__info__point">
                            <img src={arrow} alt="img" className="main__section__preschool__content__block1__info__point__img"/>
                            <p className="main__section__preschool__content__block1__info__point__text">
                                Учебная программа разработана для детей разных возрастов и уровней
                            </p>
                        </div>
                        <div className="main__section__preschool__content__block1__info__point">
                            <img src={arrow} alt="img" className="main__section__preschool__content__block1__info__point__img"/>
                            <p className="main__section__preschool__content__block1__info__point__text">
                                При обучении используются современные ассоциативные методики, способствующие быстрому запоминанию и лучшему усвоению материала
                            </p>
                        </div>
                        <div className="main__section__preschool__content__block1__info__point">
                            <img src={arrow} alt="img" className="main__section__preschool__content__block1__info__point__img"/>
                            <p className="main__section__preschool__content__block1__info__point__text">
                                На занятиях мы используем игровые методики и интерактивные приложения
                            </p>
                        </div>
                    </div>
                    <div className="main__section__preschool__content__block1__note">
                        <img src={note1} alt="img" className="main__section__preschool__content__block1__note__noteImg"/>
                    </div>
                </div>
                <div className="main__section__preschool__content__block2">
                    <div className="main__section__preschool__content__block2__note">
                        <img src={note2} alt="img" className="main__section__preschool__content__block2__note__noteImg"/>
                    </div>
                    <div className="main__section__preschool__content__block2__info">
                        <h1 className="main__section__preschool__content__block2__info__title">
                            Преимущества:
                        </h1>
                        <Advantages/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreparingForSchool;