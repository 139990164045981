import React from "react";

function Sales() {
    return (
        <div className="main__choice hystmodal" id='allSales' aria-hidden="true">
            <div className="main__choice__modal hystmodal__wrap">
                <div className="main__choice__modal__layout hystmodal__window window__teacher" role="dialog" aria-modal="true">
                    <button data-hystclose className="hystmodal__close">Закрыть</button>
                    <div className="main__choice__modal__layout__content">
                        <h1 className="main__choice__modal__layout__content__title">
                            Акции:
                        </h1>
                        <div className="main__choice__modal__layout__content__sales">
                            <div className="main__choice__modal__layout__content__sales__sale">
                                <h3 className="main__choice__modal__layout__content__sales__sale__title">
                                    «Купи сейчас»
                                </h3>
                                <p className="main__choice__modal__layout__content__sales__sale__text">
                                    При покупке абонемента в день пробного занятия Вы получает одно занятие в подарок.
                                </p>
                            </div>
                            <div className="main__choice__modal__layout__content__sales__sale">
                                <h3 className="main__choice__modal__layout__content__sales__sale__title">
                                    «Приведи друга»
                                </h3>
                                <p className="main__choice__modal__layout__content__sales__sale__text">
                                    Если по Вашей рекомендации к нам на занятия пришел Ваш знакомый и оплатил абонемент, то Вы получаете одно занятие в подарок.
                                </p>
                            </div>
                            <div className="main__choice__modal__layout__content__sales__sale">
                                <h3 className="main__choice__modal__layout__content__sales__sale__title">
                                    «Семейная скидка»
                                </h3>
                                <p className="main__choice__modal__layout__content__sales__sale__text">
                                    Занимайтесь всей семьей и получайте скидку 10% на занятия для второго и каждого следующего члена семьи (распространяется только на групповые пакеты занятий).
                                </p>
                            </div>
                            <div className="main__choice__modal__layout__content__sales__sale">
                                <h3 className="main__choice__modal__layout__content__sales__sale__title">
                                    «Дуэт»
                                </h3>
                                <p className="main__choice__modal__layout__content__sales__sale__text">
                                    Занимайтесь в приятной Вам компании только вдвоем (с родственником или другом). Платите за одного 100% стоимости индивидуальных занятий, а за второго – 50%.
                                </p>
                            </div>
                            <div className="main__choice__modal__layout__content__sales__sale">
                                <h3 className="main__choice__modal__layout__content__sales__sale__title">
                                    "Несколько направлений"
                                </h3>
                                <p className="main__choice__modal__layout__content__sales__sale__text">
                                    Если Вы выбрали для обучения в нашей школе несколько направлений, то на второе и последующие направления Вы получаете скидку 10%.
                                </p>
                            </div>
                            <p className="main__choice__modal__layout__content__sales__note">*Акции и скидки не суммируются.</p>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sales;